<template>
  <div class="autumnOct">
    <img src="/activity/autumnOct/wap/banner.png" class="banner" alt="">
    <img src="/activity/autumnOct/wap/tit1.png" class="title" alt="">
    <div class="part1">
      <ul>
        <li>
          <img src="/activity/autumnOct/wap/ye1.png" class="leaf" alt="">
          <p>
            十月，是我国的收货之季，一片片稻田闪烁着金色的光芒、饱满的硕果正摇摇欲坠，秋菊绽放、桂花飘香，金黄色的落叶在空中飘舞，人们脸上洋溢着幸福的笑容；
          </p>
        </li>
        <li>
          <img src="/activity/autumnOct/wap/ye2.png" class="leaf" alt="">
          <p>
            十月，也是黄金需求旺盛之月，十月的日子总寓意着十全十美，大吉大利，是婚嫁和新店开张的好时节，此时黄金制品总是用来作为美好祝福的象征；
          </p>
        </li>
        <li>
          <img src="/activity/autumnOct/wap/ye3.png" class="leaf" alt="">
          <p>
            十月，还是贵金属产品的投资旺季，人们会在这个丰收之月开始新一年的投资计划，因现货黄金具有起点低、双向交易、易上手等特点，所以“炒金”成为越来越多数人的选择；
          </p>
        </li>
      </ul>
    </div>
     <img src="/activity/autumnOct/wap/tit2.png" class="title" alt="">
     <div class="part2">
      <a href="https://www.rlcltd001.com/uc/signUp/real"></a>
     </div>
     <img src="/activity/autumnOct/wap/tit3.png" class="title" alt="">
     <div class="part3">
      <p>
        十月迎新，开户得<span>$300</span>现金券<br/>
        先交易再入金，给自己一个体验的机会<br/>
        首入金更有最高<span>$7500+￥500</span>投资礼包
      </p>
      <span class="detail" @click="openPop">活动详情</span>
      <a href="https://www.rlcltd001.com/uc/signUp/real"></a>
     </div>
      <img src="/activity/autumnOct/wap/tit4.png" class="title" alt="">
      <div class="part4">
        <div class="box">
          <a href="https://www.rlcltd001.com/uc/signUp/real"></a>
        </div>
      </div>
       <img src="/activity/autumnOct/wap/tit5.png" class="title" alt="">
      <div class="part5">
       <p>
        专业权威机构监管<br>
        香港金银业贸易场第081号行员
       </p>
       <img src="/activity/autumnOct/wap/pic2.png" class="pic2" alt="">
       <img src="/activity/autumnOct/wap/pic3.png" class="pic3" alt="">
       <div class="box">
          <h1>
          诚信至上，客户为本<br>
          专业、安全、国际化标准
        </h1>
        <ul>
          <li><i></i>银行级别安全体系，出金最快2小时到账；</li>
          <li><i></i>国际标准编码技术，交易直达国际市场；</li>
          <li><i></i>高端加密交易信息，严格保密用户个人信息；</li>
          <li><i></i>风控团队实时护航，保障用户无忧交易；</li>
        </ul>
       </div>
      </div>
      <div class="popup" v-if="controlPop">
      <img class="guanbi1" @click="closePop" src="/activity/autumnOct/guanbi1.png" alt="">
      <ul class="popContent">
        <li>
          <span>活动时间：</span>
          即日起至2022年10月31日23:59
        </li>
        <li>
          <span>活动对象：</span>
          首次在本公司真实交易账户入金的客户
        </li>
        <li>
          <span>活动规则：</span><br>         
          活动期间，新用户首次入金最高可领$7500+￥500微信红包<br>
          ①新客开户即领300美元现金券;<br>
          ②首次入金立即到账100美元交易现金+与入金金额对应赠金点回馈;<br>
          ③入金后咨询客服领取最高￥500微信红包;<br>
        </li>
        <li>
          <span>奖励列表：</span><br>
          <table>
            <tr>
              <th>赠送条件（美元）</th>
              <th>赠金明细</th>
              <th>总金额</th>
            </tr>
            <tr>
              <th>开立真实账户</th>
              <th>$300（现金券）+$100（赠金点）</th>
              <th>400美元</th>
            </tr>
            <tr>
              <th>首入金≥200</th>
              <th>$300（现金券）+$100（交易现金）+$200（赠金点）+￥68（微信红包）</th>
              <th>600美元+68RMB</th>
            </tr>
            <tr>
              <th>首入金≥500</th>
              <th>$300（现金券）+$100（交易现金）+$200（赠金点）+￥200（微信红包）</th>
              <th>600美元+200RMB</th>
            </tr>
            <tr>
              <th>首入金≥1000</th>
              <th>$300（现金券）+$100（交易现金）+$200（赠金点）+￥500（微信红包）</th>
              <th>600美元+500RMB</th>
            </tr>
            <tr>
              <th>首入金≥5000</th>
              <th>$300（现金券）+$100（交易现金）+$500（赠金点）+￥500（微信红包）</th>
              <th>900美元+500RMB</th>
            </tr>
            <tr>
              <th>首入金≥10000</th>
              <th>$300（现金券）+$100（交易现金）+$1100（赠金点）+￥500（微信红包）</th>
              <th>1500美元+500RMB</th>
            </tr>
            <tr>
              <th>首入金≥15000</th>
              <th>$300（现金券）+$100（交易现金）+$3200（赠金点）+￥500（微信红包）</th>
              <th>3500美元+500RMB</th>
            </tr>
          </table>
          同一身份信息仅可领取一次，赠金将在注册/入金后自动发放；
        </li>
        <li>
          <span>奖励说明：</span><br>
          <span>美元现金券</span><br>
        </li>
        <li>
          活动期间内，开立真实账户赠送100美元现金券①+100美元现金券②+100美元现金券③
        </li>
        <li>现金券使用规则：</li>
        <li>
          1、现金券将在开户当天开始分别发放，现金券①在开户当天激活；现金券②在开户后的第一天激活；现金券③在开户后的第4天激活；<br>
          2、现金券激活成后可直接交易，激活后使用有效期从当日00:00起计算，共计3天；<br>
          3、每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br>
          4、现金券①领券后，平仓盈利部分需满足入金≥100美元后方可提取；现金券②领券后，平仓盈利部分需满足入金≥200美元后方可提取，亏损部分由平台承担；现金券③领券后，平仓盈利部分需满足入金≥300美元后方可提取，亏损部分由平台承担，现金券止盈4%；<br>
          5、盈利有效期为平仓后30天内，逾期扣回；<br>
          6、现金券交易不计入活动手数。<br>
          7、现金券仅限现货黄金/白银交易品种。
        </li>
        <li>
          <span>赠金点</span>
        </li>
        <li>
          1、活动期间内，开立真实账户或首次入金≥对应金额，即可获得对应金额赠金点回馈；<br>
          2、满足赠金点领取条件后，赠金点将会发放至用户中心，客户按照不同账户类型，每交易一手现货黄金/白银，返还相对应赠金金额至用户MT4真实交易账户中（未满一手按照实际交易手数占一手的比例计算）；<br>
          3、次日交易结算时，按当天完成的交易手数计算出相应的可提取赠金；<br>
          4、可提取赠金需在用户中心进行提取，提取后返还至客户MT4真实交易账户中，赠金可提现/交易；<br>
          5、不同账户类型赠金点返还额度如下：<br>
        </li>
        <li>
          <img src="/activity/autumnOct/popImg.png" alt="">
        </li>
        <li>
          <span>交易现金</span><br>
          1、活动期间内，首次入金≥对应金额，即送100美元现金，美元现金可直接用于交易，自首次入金起30天内，用户交易满足6手黄金/白银手数可提现；<br>
          2、用户满足美元现金领取条件后，系统将会直接发放100美元现金至MT4真实交易账户。<br>
          举例：①首次入金≥200美元，即可获赠100美元交易现金，30天内交易满6手可提现。
        </li>
        <li>
          <span>微信现金红包</span><br>
          活动期间内，首次入金≥对应金额，即可联系客服咨询领取微信红包。<br>
        </li>
        <li>
          <span>注意事项</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br>
          4. 用户领取100美元交易现金, 如在30天内交易手数未满6手或未满足交易手数前进行出金，则此时平台直接扣除100美元交易现金，活动最终结算时交易账户中若仍有持仓单，若发生亏损情况，本公司将不承担任何责任；<br>
          5. 在活动有效期内，不管客户盈利或亏损，只要客户不主动终止参与本活动。如客户多次入金，都将在活动结算时统一结算；<br>
          6. 在活动有效期内，客户可在用户中心主动终止参与本活动。终止参与本活动后，本公司平台将会按活动规则进行结算；<br>
          7. 凡推广商及其名下客户不参与本活动；<br>
          8. 如本公司怀疑客户滥用或以其他方式滥用优惠，进行异常操作（如对冲订单，多账号操作等），本公司有权停止客户新交易或停用账号且取消账户所获赠金及利润及取消所有交易，并视乎情况扣除账户结余；<br>
          9. 使用本公司赠送现金券进行交易将不计入活动手数；<br>
          10. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name:'autumnOctWap',
  data(){
    return{
      controlPop:false,
    }
  },
  methods:{
    openPop(){
      this.controlPop = true;
    },
    closePop(){
      this.controlPop = false;
    }
  },
  created(){
    if(document.body.clientWidth>500){
      // this.$router.push({
      //   name:'autumnOctPC',
      // })
      window.location.href = 'https://www.iejtnk.com/#/activity/autumnOct/index';
    }
  }
}
</script>

<style lang="less" scoped>
  .autumnOct{
    width: 100%;
    background: #fdf5e6;
    .banner{
      width: 100%;
      height: 265.5px;
      display: block;
    }
    .title{
      width: 100%;
      height: 45px;
      display: block;
    }
    .part1{
      
      padding-top: 20px;
      padding-bottom: 20px;
      ul{
        li{
          width: 91%;
          display: block;
          margin: 0 auto;
          height: 220px;
          box-sizing: border-box;
          padding-top: 137px;
          position: relative;
          margin-bottom: 20px;
          p{
            color: #a86e4f;
            margin: 0 auto;
            width: 80%;
            text-align: center;
            font-size: 13px;
          }
        }
        
        li:nth-child(1){
          background: url('/activity/autumnOct/wap/li1.png') no-repeat center;
          background-size: cover;
          .leaf{
            position: absolute;
            right: -19px;
            top: -18px;
            width: 22px;
          }
        }
        li:nth-child(2){
          background: url('/activity/autumnOct/wap/li2.png') no-repeat center;
          background-size: cover;
          .leaf{
            position: absolute;
            left: -15px;
            bottom: -12px;
            width: 30px;
          }
        }
        li:nth-child(3){
          background: url('/activity/autumnOct/wap/li3.png') no-repeat center;
          background-size: cover;
          .leaf{
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 22px;
          }
        }
      }
    }
    .part2{
      width: 344px;
      height: 308px;
      display: block;
      margin: 0 auto;
       background: url('/activity/autumnOct/wap/part2.png') no-repeat center;
      background-size: cover;
      margin-top: 10px; 
      box-sizing: border-box;
      padding-top: 256px;
      a{
        display: block;
        margin: 0 auto;
        background: url('/activity/autumnOct/wap/btn1.png') no-repeat center;
        background-size: cover;
        width: 150px;
        height: 40px;
      }
    }
    .part3{
      width: 400px;
      height: 400px;
      display: block;
      margin: 0 auto;
      background: url('/activity/autumnOct/wap/part3.png') no-repeat center;
      background-size: cover;
      box-sizing: border-box;
      padding-top: 186px;
      p{
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #a86e4f;
        margin-bottom: 0px;
        span{
          color: #e34a3f;
        }
      }
      .detail{
        display: block;
        font-size: 16px;
        text-decoration: underline;
        color: #e34a3f;
        text-align: center;
        margin: 10px auto 20px;
      }
      a{
        display: block;
        margin: 0 auto;
        background: url('/activity/autumnOct/wap/btn2.png') no-repeat center;
        background-size: cover;
        width: 185px;
        height: 40px;
        margin-top: 10px;
      }
    }
    .part4{
     width: 380px;
      height: 258px;
      display: block;
      margin: 0 auto;
       background: url('/activity/autumnOct/wap/piaodai.png') no-repeat center;
      background-size: 388px 167px;
      box-sizing: border-box;
      padding-top: 10px;
      .box{
        width: 304px;
        height: 230px;
        margin: 0 auto;
        background: url('/activity/autumnOct/wap/redbag.png') no-repeat center;
        background-size: cover;
        box-sizing: border-box;
        padding-top: 130px;
         a{
            display: block;
            margin: 0 auto;
            background: url('/activity/autumnOct/wap/btn3.png') no-repeat center;
            background-size: cover;
            width: 285px;
            height: 40px;
            margin-top: 10px;
          }
      }
    }
    .part5{
      padding-top: 10px;
      position: relative;
      p{
        text-align: center;
        font-size: 13px;
        color: #a86e4f;
        line-height: 21px;
        margin-bottom: 20px;
      }
      img{
        display: block;
        margin: 0 auto;
      }
      .pic2{
        width: 300px;
        margin-bottom: 50px;
      }
      .pic3{
        width: 280px;
      }
      .box{
        background: url('/activity/autumnOct/wap/bgbottom.png') no-repeat center;
        background-size: 100% 100%;
        padding-bottom: 30px;
      }
      h1{
        text-align: center;
        font-size:18px;
        color: #a86e4f;
        line-height: 24px;
      }
      ul{
        list-style-type:disc;
        li{
          text-align: left;
          font-size: 13px;
          color: #a86e4f;
          padding-left: 67px;
          i{
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #a86e4f;
            border-radius: 50px;
            margin-right: 5px;
          position: relative;
          top: -1px;
          }
        }
      }
    }
    .popup{
      width: 95%;
      height: 400px;
      border-radius: 15px;
      background: #fdf5e6;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%;-50%);
      box-sizing: border-box;
      padding: 5px;
      box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
      overflow-y: scroll;
      .guanbi1{
        float: right;
        cursor: pointer;
        margin-top: 8px;
        margin-right: 10px;
      }
      .popContent{
        box-sizing: border-box;
        padding: 10px 15px 10px;
        li{
          font-size: 14px;
          color: #a86e4f;
          line-height: 28px;
          span{
            font-weight: 600;
            color: #e34a3f;
          }
          img{
            width: 100%;
            height: auto;
            margin: 20px 0;
          }
          table{
            border: 1px solid #e34a3f;
            border-radius: 15px;
            tr{
              th{
                text-align: center;
                font-weight: 500;
                line-height: 25px;
                border-bottom: 1px solid #d2a765;
                border-right: 1px solid #d2a765;
                font-size: 13px;
                padding: 2px;
              }
              th:last-child{
                border-right: 1px solid transparent;
              }
            }
            tr:nth-child(1){
              th{
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
</style>